<template>
    <div class="page-invite-gift">
        <headeBar left-arrow :color="header_color" :title="header_title" :background="header_background"
            :opacity="header_opacity" :placeholder="false" @click-left="newAppBack" @setBarHeitht="setBarHeitht" />
        <div class="contain">
            <div class="banner-top">
                <div class="header-rule-btn" @click="toRule"></div>
            </div>
            <div class="main-label">好友首次加入超鹿运动，可得新人专属权益</div>
            <div class="top-action" ref="refInvite">
                <div class="top-invite-btn" @click="nowInvite('158')"></div>
                <div class="share-cont" v-if="!isResume">
                    <div class="share-left row-around-center" @click="faceInvite">
                        <div class="share-left-icon"></div>
                        <div class="share-text">面对面邀请</div>
                    </div>

                    <div class="share-right row-around-center" @click="posterInvite">
                        <div class="share-right-icon"></div>
                        <div class="share-text">生成分享海报</div>
                    </div>
                </div>
            </div>
            <div class="main" ref="refMain">
                <div class="invite-main-cont">
                    <div class="top-title">我的邀请</div>
                    <div class="invite-main-nav">
                        <div class="tab-model" :class="{ active: listType === 1 }" @click="listChange(1)">
                            <span class="tab-model-label">
                                邀请中
                                <span class="coner" v-if="newInvitingCount">{{ newInvitingCount }}</span>
                            </span>
                        </div>
                        <div class="tab-model" :class="{ active: listType === 2 }" @click="listChange(2)">
                            <span class="tab-model-label">
                                我的奖励
                                <span class="coner" v-if="rewardCount">{{ rewardCount }}</span>
                            </span>
                        </div>
                        <div class="tab-model" :class="{ active: listType === 3 }" @click="listChange(3)">
                            <span class="tab-model-label">
                                历史邀请
                                <!-- <span class="coner">4</span> -->
                            </span>
                        </div>

                    </div>
                    <div class="userlist-cont">
                        <div class="list-empty" v-if="finished && dataList.length === 0">
                            <img class="empty-img" src="https://img.chaolu.com.cn/ACT/invite-gift/null.png" />
                            <p class="empty-text">{{ emptyText() }}</p>
                            <p class="empty-text et1">快去邀请好友赢3天超鹿卡</p>
                        </div>
                        <template v-else>
                            <div v-show="listType === 1" class="main-top-tip">
                                <p class="p-tip">有{{ invitingCount }}个好友已接受邀请但还未进馆运动</p>
                            </div>
                            <div class="userlist">
                                <div class="user-model" v-for="item in dataList">
                                    <div class="user-model-main">
                                        <div v-show="item.isNewUser" class="new-user">新用户</div>
                                        <div class="user-photo" :class="{ headbg: !item.headImg }">
                                            <img :src="item.headImg" v-if="item.headImg" />
                                        </div>
                                        <div class="user-message">
                                            <div class="user-name ellipsis">
                                                {{ item.nickName || '--' }}
                                            </div>
                                            <div class="invite-date" v-show="listType === 1">
                                                邀请日期：{{ item.createTime || '--' }}
                                            </div>
                                            <div class="invite-rewardtype" v-show="listType === 2">
                                                {{ item.rewardType === 1 ? '邀请好友进馆运动' : '邀请新用户注册' }}
                                            </div>
                                            <div class="invite-date" v-show="listType === 3 && item.inviteStatus === 2">
                                                进馆日期：{{ item.enterTime }}
                                            </div>
                                            <div class="invite-date" v-show="listType === 3 && item.inviteStatus === 3">
                                                失效日期：{{ item.expireTime }}
                                            </div>
                                        </div>
                                        <div class="btn-cont">
                                            <template v-if="listType === 1">
                                                <div class="user-invite-btn" v-if="!isResume" @click="nowInvite('164')">
                                                    催催Ta
                                                </div>
                                                <div class="last-date">距邀请失效仅剩{{ item.remainingDays }}天</div>
                                            </template>
                                            <template v-if="listType === 2">
                                                <div class="get-reward" @click="getReward(item)"
                                                    v-if="item.status === 0">
                                                    领取奖励</div>
                                                <div class="reward-cont" v-if="item.status === 1">
                                                    <div class="has-get-reward">
                                                        已领取
                                                        <!-- {{ rewardTypeZH(item) }} -->
                                                    </div>
                                                    <div class="invite-date">领取日期：{{ item.receiveDate }}</div>
                                                </div>
                                            </template>
                                            <template v-if="listType === 3">
                                                <div class="user-invite-status" v-if="item.inviteStatus === 2">邀请成功
                                                </div>
                                                <div class="user-invite-status" style="color: #FFAC91"
                                                    v-else-if="item.inviteStatus === 3">邀请失效</div>
                                            </template>
                                        </div>
                                    </div>
                                    <p v-if="item.failMsg" class="fail-msg">{{ item.failMsg }}</p>
                                </div>
                            </div>
                            <div class="list-finished" @click="loadMore">{{ finished ? '已全部加载完毕' : '点击加载更多' }}</div>
                        </template>
                    </div>

                </div>
                <img alt="" class="rule-img" ref="refRule" src="https://img.chaolu.com.cn/ACT/invite-gift/202412/rule.png?v=1" />
                <div
                    style="padding-bottom: constant(safe-area-inset-bottom); padding-bottom: env(safe-area-inset-bottom);margin-top: 50px;">
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="fixed-invite-cont" v-show="footerBtnShow">
                <div class="fixed-invite-btn" @click="nowInvite('158')"></div>
            </div>
        </transition>
        <transition name="fade">
            <div class="ap-confirm3 ap-cover" v-if="showCode" @touchmove.prevent>
                <div class="pop-code">
                    <div class="sun-box">
                        <img class="face-face-code" :src="userPoster" alt="" />
                    </div>
                    <div class="sun-tip">让好友使用微信扫一扫</div>
                    <div class="share-close" @click="showCode = false">
                        <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/inviter/share_close.png"
                            alt="" />
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="ap-confirm3 ap-cover" v-if="showResume" @touchmove.prevent @click="showResume = false">
                <div class="ac5-con">
                    <div class="ac5-title">温馨提示</div>
                    <p class="ac5-p">亲爱的续转会员，为了让您享受更优质的超鹿服务，我们为您独家升级新人邀请礼包。</p>
                    <p class="ac5-p mt12">1、续转会员有效期内，邀请1位好友，最高可延长13天年卡权益；</p>
                    <p class="ac5-p mt12">2、原有获得且未领取的128礼包仍可领取。</p>
                    <p class="ac5-p mt12">
                        由此给您带来的不变，我们深感歉意。如有疑问，可拨打客服电话：<br />
                        400-700-5678
                    </p>
                    <div class="ac5-con-btn" @click="goResume">查看新的邀请奖励</div>
                </div>
            </div>
        </transition>
        <van-overlay :show="isShareWx" :z-index="1500" @click="isShareWx = false">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2021/share-mini.png"
                    class="tip-img" />
            </div>
        </van-overlay>
        <van-popup v-model="showGet" position="center" class="pop-get">
            <div class="pop-get-title">领取3天超鹿卡</div>
            <div class="pop-get-img">
                <img src="https://img.chaolu.com.cn/ACT/invite-gift/reward.png" />
            </div>
            <div class="pop-get-label">请选择使用城市<span class="pg-tips">（选择后无法退改）</span></div>
            <div class="pg-select" @click="showCity = true">
                <span class="cityname">{{ getCityName() }}</span>
                <van-icon name="arrow" />
            </div>
            <div class="pg-sub" @click="sure">确认领取</div>
        </van-popup>
        <van-popup v-model="showCity" position="bottom" safe-area-inset-bottom class="pop">
            <van-picker title="选择城市" :columns="cityList" @confirm="onConfirm" @cancel="showCity = false" show-toolbar />
        </van-popup>
        <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false"
            @share-success="isShareShow = false" :shareItem="shareItem" :miniSharePoster="appTypeStr === 'mini'">
            <template slot="createDom">
                <div class="canvascss">
                    <img src="https://img.chaolu.com.cn/ACT/invite-gift/20240621/sharebg2.png" class="canvascss_bg" />
                    <div class="lt">
                        <img :src="userHeadImg" class="uhead" />
                        <div class="pub_onefull">
                            <div class="uname">{{ userNickName }}</div>
                            <div>邀请你来超鹿运动</div>
                        </div>
                    </div>
                    <div class="code-box"><img class="ucode" :src="userPoster" /></div>
                </div>
            </template>
        </common-share>
        <img src="https://img.chaolu.com.cn/ACT/invite-gift/sun_bg.png" style="display: none;" />
    </div>
</template>
<script>
import { newAppBack, initBack, saveCreateImg, webAppShare, defaultApp, toLoginPage, loginOut } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import commonShare from '@/components/commonShare';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';
import headerMixin from '@/mixin/headerMixin';
import { getFormatDate } from '@/lib/utils';
export default {
    components: {
        headeBar,
        commonShare,
    },
    mixins: [userMixin, headerMixin],
    data() {
        return {
            cp: this.$route.query.cp, // 渠道
            userHeadImg: '',
            userNickName: '',
            userPoster: '',
            // 续转会员
            isResume: false,
            showResume: false,
            isShareWx: false,
            // 列表
            // 右上角数量
            newInvitingCount: 0,
            rewardCount: 0,
            // 提示数量
            invitingCount: 0,
            newUserRewardCount: 0,
            enterVenueRewardCount: 0,
            listType: 1,
            dataList: [
                // { isNewUser: true, createTime: '2024-01-03', nickName: '啊实打实的撒旦撒打算大萨达四大四大四大四大四大打撒', failMsg: '实打实大撒大声地', status: 1, receiveDate: '2024-03-26', inviteStatus: 3 },
                // { isNewUser: true, createTime: '2024-01-03', nickName: '啊实打实的撒旦撒打算大萨达四大四大四大四大四大打撒', status: 0, inviteStatus: 2 }
            ],
            finished: false,
            pageNum: 1,
            pageSize: 10,
            shareItem: ['posterfriend', 'postermessage', 'save'],//['message', 'friend', 'save'],
            invitationSucceededNumber: 0,  // 小班课拉新-邀请成功
            invitedNumber: 0,  // 小班课拉新-邀请中
            groupInvitedNum: 0,// 团课拉新-邀请中
            groupSuccessNum: 0,// 团课拉新-邀请成功
            // 底部按钮是否展示
            footerBtnShow: false,
            showCode: false, // 弹窗3,分享弹窗
            showGet: false,
            showCity: false,
            isShareShow: false, // 是否显示分享弹窗
            shareParams: {
                title: '送你1天免费体验健身、团课',
                content: '送你1天免费体验健身、团课',
                multiple: '1',
                userName: 'gh_7f5b57b6a76e',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                miniImage: 'https://img.chaolu.com.cn/ACT/invite-gift/20240621/mini2.png',
                path: '/pages/subPages/experience/index',
            },
            cityList: [],
            selectCityItem: undefined
        }
    },
    methods: {
        saveCreateImg,
        newAppBack,
        getReward(item) {
            this.rewardItem = item;
            this.showGet = true;
        },
        sure() {
            if (!this.selectCityItem) {
                this.$toast('请选择城市');
                return;
            }
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            this.$axios.post(this.baseURLApp + '/invitation/myinvite/myreward/receive', {
                userId: this.userId,
                rewardId: this.rewardItem.id,
                cityId: this.selectCityItem.value
            }).then((res) => {
                // let _index = this.dataList.findIndex(x => x.id === item.id)
                // let _receiveDate = getFormatDate(new Date())
                // let _item = Object.assign(item, { receiveDate: _receiveDate, status: 1 })
                // console.log('_index _item', _index, _receiveDate, _item)
                // this.$set(this.dataList, _index, _item)
                this.$toast.clear();
                this.$router.push('/month-card/my-card-detail?userMembershipCardId=' + res.data.rewardId)
            }).catch(() => {
                this.$toast.clear()
            })
        },
        getCityName() {
            if (this.selectCityItem) {
                return this.selectCityItem.text;
            }
            return '请选择'
        },
        getCityList() {
            this.$axios.post(`${this.baseURLApp}/scheduleLesson/cityList`, { userId: this.userId }).then(res => {
                const cityList = res.data.map(d => {
                    const model = {
                        text: d.areaName,
                        value: d.cityId
                    }
                    if (this.cityId == d.cityId) {
                        this.selectCityItem = model;
                    }
                    return model
                });
                this.cityList = cityList;
                console.log(this.cityList);
                // let item = this.cityList.find(r => {
                //     return r.cityId === this.currentCityId
                // })
                // if (item) {
                //     this.cityName = item.areaName
                //     this.currentCityId = item.cityId
                // }
            })
        },
        onConfirm(item) {
            this.showCity = false;
            this.selectCityItem = item;
            console.log(item);
        },
        setBarHeitht(h) {
            this.barHeight = h;
        },
        toRule() {
            const ref = this.$refs.refRule;
            const main = this.$refs.refMain;
            window.scrollTo({
                letf: 0,
                top: ref.offsetTop + main.offsetTop - (this.barHeight || 0) + 1,
                behavior: 'smooth'
            });
        },
        init() {
            this.shareParams.path = '/pages/subPages/experience/index?inviteUid=' + this.userId;
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: this.shareParams.miniImage,
                    shareTitle: this.shareParams.title,
                    shareUrl: this.shareParams.path,
                },
            })
            this.getIsResume();
            this.getNewInvitingCount();
            this.getRewardCount();
            this.getRewardTotalcount();
            this.getData();
            this.getInvitePoster();
            // this.getMicroUserNumber();
            // this.getGroupUserNumber();
            this.getCityList();
            this.countPoint('26', '116', '157', this.userId);
            this.cp && this.countPoint('26', '117', this.cp, this.userId);
        },
        getGroupUserNumber() {
            this.$axios
                .post(this.baseURLApp + "/schedule/invitation/userNumber", {
                    userId: this.userId,
                    token: this.token,
                    hasWx: true
                })
                .then((res) => {
                    this.groupInvitedNum = res.data.invitedNumber;
                    this.groupSuccessNum = res.data.invitationSucceededNumber;
                })
        },
        getNewInvitingCount() {
            this.$axios.post(this.baseURLApp + '/invitation/myinvite/inviting/newcount', { userId: this.userId }).then((res) => {
                this.newInvitingCount = res.data.count
            })
        },
        clearNewInvitingCount() {
            this.$axios.post(this.baseURLApp + '/invitation/myinvite/inviting/newcount/clear', { userId: this.userId }).then((res) => {
                this.newInvitingCount = 0
            })
        },
        getRewardTotalcount() {
            this.$axios.post(this.baseURLApp + '/invitation/myinvite/myreward/totalcount', { userId: this.userId }).then((res) => {
                this.newUserRewardCount = res.data.newUserRewardCount
                this.enterVenueRewardCount = res.data.enterVenueRewardCount
                this.invitingCount = res.data.invitingCount
            })
        },
        getRewardCount() {
            this.$axios.post(this.baseURLApp + '/invitation/myinvite/myreward/newcount', { userId: this.userId }).then((res) => {
                this.rewardCount = res.data.count
            })
        },
        clearRewardCount() {
            this.$axios.post(this.baseURLApp + '/invitation/myinvite/myreward/newcount/clear', { userId: this.userId }).then(() => {
                this.rewardCount = 0
            })
        },
        getIsResume() {
            this.$axios.post(this.baseURLApp + '/invitation/myinvite/isidentity', { userId: this.userId }).then((res) => {
                this.isResume = res.data.identity;
            })
        },
        getMicroUserNumber() {
            this.$axios.post(this.baseURLApp + '/newMicro/invitation/userNumber', {
                userId: this.userId,
                hasWx: true,
            }).then((res) => {
                if (res.data) {
                    this.invitationSucceededNumber = res.data.invitationSucceededNumber
                    this.invitedNumber = res.data.invitedNumber
                }
            })
        },
        getInvitePoster() {
            if (this.userPoster) {
                // this.isShareShow = true
                return
            }
            this.$axios.post(this.baseURLApp + '/userInvita/queryInvitePoster', { userId: this.userId }).then((res) => {
                console.log('/userInvita/queryInvitePoster', res.data.nickName)
                this.userPoster = 'data:image/png;base64,' + res.data.poster
                this.userHeadImg = res.data.headImg
                this.userNickName = res.data.nickName
            })
        },
        getData() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            let params = {
                userId: this.userId,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }
            let _url = ''
            switch (this.listType) {
                case 1: //邀请中
                    _url = this.baseURLApp + '/invitation/myinvite/inviting'
                    break
                case 2: //邀请中
                    _url = this.baseURLApp + '/invitation/myinvite/myreward'
                    break
                case 3: //邀请中
                    _url = this.baseURLApp + '/invitation/myinvite/historylist'
                    break
            }
            this.$axios.post(_url, params)
                .then((res) => {

                    this.dataList.push(...res.data)
                    if (res.data.length < this.pageSize) {
                        this.finished = true
                    }
                    this.$toast.clear()
                })
                .catch(() => {
                    this.$toast.clear()
                })
        },
        listChange(val) {
            if (val !== this.listType) {
                this.listType = val
                this.pageNum = 1
                this.finished = false
                this.dataList = []
                this.getData()
                this.getRewardTotalcount()
            }
            if (val === 1) {
                this.clearNewInvitingCount()
            }
            if (val === 2) {
                this.clearRewardCount()
            }
        },
        nowInvite(val) {
            if (!this.userId) {
                this.$store.commit('openLoginPopup', true)
                return;
            }
            this.countPoint('26', '116', val, this.userId);
            // 续转会员
            if (this.isResume) {
                this.showResume = true
                return
            }
            if (this.appTypeStr === 'mini') {
                this.isShareWx = true
            } else {
                let params = {
                    scene: 0,
                    ...this.shareParams
                }
                let miniParams = JSON.parse(JSON.stringify(params))
                miniParams.image = miniParams.miniImage
                webAppShare('3', miniParams)
            }
        },
        goRookie() {
            this.$router.push('/superdeer-activity/rookie-202203/record')
        },
        faceInvite() {
            this.countPoint('26', '116', '159', this.userId);
            this.getInvitePoster();
            this.showCode = true;
        },
        posterInvite() {
            this.countPoint('26', '116', '160', this.userId);
            // this.shareItem = ['minifriend', 'postermessage', 'save']
            this.getInvitePoster();
            this.isShareShow = true;
        },
        loadMore() {
            if (this.finished) {
                return
            }
            this.pageNum++;
            this.getData()
        },
        goJumpNative() {
            if (this.appTypeStr == 'and') {
                App.intentToActivity(
                    JSON.stringify({
                        className: '.ui.coupons.MyCouponsForCommonActivity',
                        data: [],
                    })
                )
            }
            if (this.appTypeStr == 'ios') {
                window.webkit.messageHandlers.CustomViewController.postMessage({
                    className: 'SDCouponViewController',
                    params: { couponType: '6' },
                })
            }
        },
        lookUpCoupons() {
            this.goJumpNative()
        },
        emptyText() {
            var _result = ''
            switch (this.listType) {
                case 1:
                    _result = '暂无邀请中的记录'
                    break
                case 2:
                    _result = '暂未获得奖励'
                    break
                case 3:
                    _result = '暂无邀请记录'
                    break
            }
            return _result
        },
        goResume() {
            this.showResume = false;
            defaultApp();
            window.location.href = `${this.baseURL}/index/identityInvite?userId=${this.userId}`
        },
        rewardTypeZH(item) {
            if (item.isTransferData == 1) return '已领88礼包'
            if (item.rewardType === 1) return '已领88礼包'
            if (item.rewardType === 2) return '已领6元券'
        }
    },
    mounted() {
        const bt = this.$refs.refInvite.offsetTop;
        this.bindHeaderScroll(window, 200, (scrollTop, morehalf) => {
            if (scrollTop > bt) {
                this.footerBtnShow = true;
            } else {
                this.footerBtnShow = false;
            }
        });
    },
    async created() {
        console.log(this.$route.query);
        initBack();
        await this.$getAllInfo(['userId', 'cityId']);
        if (!this.userId) {
            this.$store.commit('openLoginPopup', true)
        } else {
            this.init();
        }
    }
}
</script>
<style lang="less" scoped>
.page-invite-gift {
    background: #7DCAFF;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .banner-top {
        position: relative;
        min-height: 1000px;
        background: #f5f5f5 url('https://img.chaolu.com.cn/ACT/invite-gift/20240621/headbg.png');
        background-size: 100% 100%;

        .header-rule-btn {
            position: absolute;
            right: 0;
            top: 350px;
            width: 48px;
            height: 128px;
            background: url('https://img.chaolu.com.cn/ACT/invite-gift/rule-button.png');
            background-size: 100% 100%;
        }
    }

    .main-label {
        font-size: 26px;
        color: #FFFFFF;
        text-align: center;
        font-weight: bold;
        line-height: 1;
        margin-top: 38px;
    }

    .top-action {
        overflow: hidden;

        .top-invite-btn {
            margin: 0 auto;
            width: 614px;
            height: 124px;
            background: url('https://img.chaolu.com.cn/ACT/invite-gift/invite_sub.png');
            background-size: 100% 100%;
        }

        .share-cont {
            margin: 20px 85px 63px;
            display: flex;
            justify-content: space-between;
        }

        .share-left,
        .share-right {
            height: 56px;
            text-align: center;
            background: #C5E8FF;
            border-radius: 28px;
            width: 273px;
            justify-content: center;
        }

        .share-left-icon {
            width: 32px;
            height: 32px;
            background: url('https://img.chaolu.com.cn/ACT/invite-gift/icon-code.png');
            background-size: 100% 100%;
        }

        .share-right-icon {
            width: 32px;
            height: 32px;
            background: url('https://img.chaolu.com.cn/ACT/invite-gift/icon-poster.png');
            background-size: 100% 100%;
        }

        .share-text {
            margin-left: 15px;
            font-size: 28px;
            height: 32px;
            line-height: 34px;
            font-weight: bold;
            color: #0943A4;
        }
    }
    .medal{
       margin:0 32px 40px;
    }

    .main {
        padding: 0 32px;
        padding-bottom: 144px;
        position: relative;

        .invite-main-cont {
            background: #FF783D;
            border-radius: 32px;
            box-shadow: 0px 0px 32px 0px rgba(255, 255, 255, 0.8) inset;
            min-height: 770px;

            .top-title {
                width: 312px;
                height: 74px;
                background: url(https://img.chaolu.com.cn/ACT/invite-gift/title_bg.png);
                background-size: 100% 100%;
                text-align: center;
                color: #734637;
                font-size: 36px;
                font-weight: bold;
                line-height: 74px;
                margin: 0 auto;
                position: relative;
                top: -11px;
                margin-bottom: 22px;
            }

            .invite-main-nav {
                position: relative;
                overflow: hidden;
                display: flex;
                width: 600px;
                margin: 0 auto;
                justify-content: space-between;

                .tab-model {
                    width: 190px;
                    text-align: center;
                    position: relative;
                    font-size: 28px;
                    color: #fff;
                    font-weight: bold;
                    height: 56px;
                    line-height: 56px;
                    border-radius: 16px;
                    transition: background 0.5s;

                    .tab-model-label {
                        position: relative;

                        .coner {
                            position: absolute;
                            margin-left: 5px;
                            height: 30px;
                            line-height: 30px;
                            width: 30px;
                            background: #F03C18;
                            border-radius: 50%;
                            border: 2px solid #FFFFFF;
                            font-size: 20px;
                            color: #fff;
                            overflow: hidden;
                        }
                    }
                }

                .active {
                    background: #FFF49A;
                    color: #944D2E;
                }
            }


            .userlist-cont {
                margin-top: 22px;
                padding-top: 56px;
                padding-bottom: 28px;
                background: url(https://img.chaolu.com.cn/ACT/invite-gift/top.png) no-repeat;
                background-size: 100% auto;

                .main-top-tip {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 520px;
                    padding: 16px 0;
                    background: rgba(253, 206, 167, 0.5);
                    border-radius: 29px;

                    .p-tip {
                        font-size: 24px;
                        color: #ffffff;
                        line-height: 1;
                        text-align: center;
                        font-weight: bold;
                    }
                }

                .list-empty {
                    margin-top: 125px;
                    padding-bottom: 117px;
                    text-align: center;

                    .et1 {
                        margin-top: 12px;
                    }

                    .empty-img {
                        width: 120px;
                        height: 120px;
                        margin: 0 auto 45px;
                    }

                    .empty-text {
                        font-size: 24px;
                        color: #fff;
                        line-height: 24px;
                        text-align: center;
                    }
                }

                .userlist {
                    border-radius: 16px;
                    padding: 0 32px;

                    .user-model {
                        background: #FFF9E9;
                        border-radius: 16px;
                        margin-bottom: 12px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        .user-model-main {
                            padding: 0 24px;
                            display: flex;
                            align-items: center;
                            height: 176px;
                            position: relative;
                        }

                        .new-user {
                            position: absolute;
                            top: 4px;
                            left: 4px;
                            width: 76px;
                            height: 30px;
                            background: #FF3D3D;
                            font-size: 18px;
                            color: #FFFFFF;
                            border-radius: 16px 0px 16px 0px;
                            text-align: center;
                            line-height: 32px;
                        }

                        .invite-rewardtype {
                            margin-top: 18px;
                            font-size: 20px;
                            color: #886559;
                        }


                        .user-invite-status {
                            font-size: 24px;
                            font-weight: bold;
                            color: #886559;
                        }

                        .user-photo {
                            width: 96px;
                            height: 96px;
                            border-radius: 50%;
                            overflow: hidden;
                            border: 2px solid #fe7b4f;
                            flex-shrink: 0;
                        }

                        .headbg {
                            background: #f5f5f5;
                        }

                        .user-message {
                            width: 235px;
                            margin: 0 0 0 18px;

                            .user-name {
                                font-size: 24px;
                                font-weight: bold;
                                color: #1F1F1F;
                            }
                        }

                        .invite-date {
                            margin-top: 16px;
                            font-size: 20px;
                            white-space: nowrap;
                            // transform: scale(0.8);
                            // transform-origin: 0 50%;
                            color: #886559;
                        }

                        .btn-cont {
                            flex: 1;
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-end;
                            flex-direction: column;

                            .has-get-reward {
                                text-align: right;
                                font-size: 24px;
                                font-weight: bold;
                                color: #F5683A;
                                line-height: 1
                            }

                            .user-invite-btn {
                                width: 130px;
                                height: 52px;
                                background: linear-gradient(0deg, #F5CC3D 0%, #FFF49A 100%);
                                border-radius: 10px;
                                color: #371717;
                                text-align: center;
                                line-height: 54px;
                                font-size: 22px;
                                margin: -16px 0 12px;
                            }

                            .reward-cont .invite-date {
                                margin-top: 24px;
                                text-align: right;
                                transform-origin: 50% 50%;
                            }
                        }
                    }

                    .fail-msg {
                        color: #F03C18;
                        padding: 0 33px 23px;
                        font-size: 20px;
                        margin-top: -24px;
                    }


                    .get-reward {
                        float: right;
                        width: 130px;
                        height: 52px;
                        line-height: 54px;
                        border-radius: 10px;
                        font-size: 22px;
                        background: linear-gradient(0deg, #1D6FEB 0%, #7DB0FF 100%);
                        font-weight: bold;
                        color: #ffffff;
                        text-align: center;
                    }

                    .last-date {
                        font-size: 20px;
                        color: #886559;
                        text-align: right;
                        line-height: 1;
                    }
                }

                .list-finished {
                    font-size: 20px;
                    font-weight: 400;
                    color: #fff;
                    text-align: center;
                    margin-top: 28px;
                }

            }
        }

        .rule-img {
            margin-top: 40px;
        }

        .invite-bottom-img {
            width: 91.2%;
            margin: 40px auto 62px;
            display: block;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .fixed-invite-cont {
        bottom: 0;
        position: fixed;
        width: 100%;
        height: 160px;
        background: linear-gradient(0deg, #7DCAFF 0%, #CCEAFF 100%);
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        box-sizing: content-box;

        .fixed-invite-btn {
            margin: 0 auto;
            margin-top: 13px;
            width: 614px;
            height: 124px;
            background: url('https://img.chaolu.com.cn/ACT/invite-gift/invite_sub.png');
            background-size: 100% 100%;
        }
    }

    .ap-cover {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background-color: rgba(16, 16, 16, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pop-code {
        width: 590px;
        height: 670px;
        background-image: url(https://img.chaolu.com.cn/ACT/invite-gift/20240621/sun_bg.png);
        background-size: 100% 100%;
        position: relative;

        .sun-box {
            width: 434px;
            height: 434px;
            margin: 166px auto 0;
            background: url(https://img.chaolu.com.cn/ACT/invite-gift/sun_box.png);
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .face-face-code {
            width: 350px;
            height: 350px;
        }

        .sun-tip {
            font-size: 22px;
            color: #2C67C6;
            margin-top: 8px;
            text-align: center
        }
    }

    .pop-get {
        border-radius: 24px;
        width: 590px;
        height: 763px;
        background: linear-gradient(0deg, #E4F6FF 0%, #FFF6EB 100%);
        padding: 50px 58px;

        .pop-get-title {
            font-size: 40px;
            color: #622009;
            line-height: 44px;
            font-weight: bold;
            line-height: 1;
            text-align: center;
        }

        .pop-get-img {
            width: 395px;
            height: 243px;
            border-radius: 12px;
            margin: 54px auto 61px;
            background: #f5f5f5;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .pop-get-label {
            text-align: center;
            color: #242831;
            font-size: 26px;
            line-height: 1;
            margin-bottom: 28px;

            .pg-tips {
                font-size: 24px;
                color: #9AA1A9
            }
        }

        .pg-select {
            height: 92px;
            background: #FFFFFF;
            border-radius: 46px;
            border: 2px solid #FF5946;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 33px;

            .cityname {
                font-size: 28px;
                color: #242831;
                line-height: 1;
                font-weight: bold;
            }
        }

        .pg-sub {
            height: 92px;
            background: linear-gradient(90deg, #FF5F3A 0%, #FF3E7D 100%);
            border-radius: 46px;
            color: #fff;
            font-weight: bold;
            font-size: 32px;
            line-height: 92px;
            text-align: center;
            margin-top: 30px;
        }
    }

    .ac4-con {
        position: relative;
        width: 630px;
        padding-top: 90px;
        height: 800px;
        background-image: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/inviter/alert-rule-bg.png');
        background-size: 100% 100%;
        display: flex;
        justify-content: center;

        .ac4-con-sec1 {
            overflow: scroll;
            position: relative;
            height: 680px;
            width: 100%;
            padding-top: 40px;

            img {
                width: 566px;
                margin: 0 auto;
                display: block;
            }
        }

        .ac4-con-close {
            left: 50%;
            top: auto;
            bottom: -78px;
            transform: translateX(-50%);
        }
    }

    .ac5-con {
        background: #ffffff;
        border-radius: 12px;
        position: relative;
        width: 630px;

        .ac5-title {
            width: 100%;
            height: 36px;
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #242831;
            line-height: 36px;
            text-align: center;
            margin: 64px 0 32px 0;
        }

        .ac5-p {
            width: 510px;
            margin: 0 auto;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6c727a;
            line-height: 44px;
            text-align: left;
        }

        .mt12 {
            margin-top: 24px;
        }

        .ac5-con-btn {
            font-size: 32px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff6e00;
            line-height: 32px;
            width: 100%;
            text-align: center;
            padding: 38px 0;
            margin-top: 34px;
            border-top: 2px solid #eaeaea;
        }
    }


    .share-close {
        width: 60px;
        display: block;
        margin: 60px auto 0;
    }

    .share-close img {
        width: 100%;
        display: block;
    }

    .tip-box {
        position: relative;

        .tip-img {
            position: absolute;
            top: 7px;
            right: 130px;
            width: 300px;
        }
    }

    @bei: 1px;

    .canvascss {
        position: fixed;
        top: -200%;
        // top: 0;
        width: @bei*600;
        height: @bei*1079;
        margin: 0 auto;
        box-sizing: border-box;

        .canvascss_bg {
            width: 100%;
            height: 100%;
        }

        .lt {
            position: absolute;
            left: @bei*40;
            top: @bei*56;
            display: flex;
            align-items: center;
            color: #fff;
            font-size: @bei*16;

            .uhead {
                width: @bei*64;
                height: @bei*64;
                border-radius: 50%;
                margin-right: @bei*20;
                font-size: @bei*20;
            }

            .uname {
                font-weight: bold;
                margin-bottom: @bei*8;
            }
        }


        .code-box {
            width: @bei*144;
            height: @bei*144;
            background: url(https://img.chaolu.com.cn/ACT/invite-gift/code-box.png);
            background-size: 100% 100%;
            padding: @bei*10;
            position: absolute;
            left: @bei*132;
            bottom: @bei*32;
        }

        .ucode {
            z-index: 1;
            border-radius: 50%;
            overflow: hidden;
        }
    }

    /deep/.canvas-box {
        padding-left: 40px !important;
        padding-right: 40px !important;
        box-sizing: border-box;

        .create-img {
            width: 100% !important;
        }
    }
}
</style>
